import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lead: null,
    contact: null,
    stages: [],
    pipeline: null,
    crifData: null,
    creditScore: null,
    creditScoreVersion: null,
    crifScoreMsg: "",
    obligation: null,
    obligationDetailed: null,
    loanOfferDetails: null,
    enquiries: [],
    consent: [],
    notes: [],
    bankDetails: {},
};

export const LeadSlice = createSlice({
    name: "lead",
    initialState,
    reducers: {
        addLeadData: (state, action) => {
            state.lead = action.payload;
            state.contact = action.payload?.Contact || null;
        },
        addStages: (state, action) => {
            state.stages = action.payload;
        },
        addPipelineData: (state, action) => {
            state.pipeline = action.payload;
        },
        setCreditScore: (state, action) => {
            state.creditScore = action.payload;
        },
        setCreditScoreVersion: (state, action) => {
            state.creditScoreVersion = action.payload;
        },
        setCRIFData: (state, action) => {
            state.crifData = action.payload;
        },
        setCRIFScoreMsg: (state, action) => {
            state.crifScoreMsg = action.payload;
        },
        setObligation: (state, action) => {
            state.obligation = action.payload.obligation;
            state.obligationDetailed = action.payload.obligationDetailed;
        },
        setEnquiries: (state, action) => {
            state.enquiries = action.payload;
        },
        setConsent: (state, action) => {
            state.consent = action.payload;
        },
        setNotes: (state, action) => {
            state.notes = action.payload;
        },
        setLoanOffeDetails: (state, action) => {
            state.loanOfferDetails = action.payload;
        },
        setBankDetails: (state, action) => {
            state.bankDetails = action.payload;
        },
    },
});

// * Selectors
export const selectNotes = (state) => state.lead.notes;
export const selectConsent = (state) => state.lead.consent;
export const selectEnquiries = (state) => state.lead.enquiries;
export const selectStages = (state) => state.lead.stages;
export const selectContactDetails = (state) => state.lead.contact;
export const selectLead = (state) => state.lead.lead;
export const selectCustomerLead = (state) => state.lead;
export const selectPipeline = (state) => state.lead.pipeline;
export const selectCreditScore = (state) => state.lead.creditScore;
export const selectCRIFData = (state) => state.lead.crifData;
export const selectCRIFScoreMsg = (state) => state.lead.crifScoreMsg;
export const selectObligation = (state) => state.lead.obligation;
export const selectObligationDetailed = (state) => state.lead.obligationDetailed;
export const selectLoanOfferDetails = (state) => state.lead.loanOfferDetails;
export const selectCustomerDocument = (state) => state.lead.lead?.Contact?.CustomerDocuments || [];
export const selectProfilePicture = (state) => {
    const documents = state.lead.lead?.Contact?.CustomerDocuments || [];

    if (documents?.length) {
        const photographDocument = documents.filter((doc) => doc.docType === "photograph");
        let profilePicture = null;
        if (photographDocument.length) {
            profilePicture = `${process.env.REACT_APP_DOCUMENTS_BASE_URL}${photographDocument[0].docUpload}`;
        }
        return profilePicture;
    } else {
        return null;
    }
};
export const selectPreApprovedLoans = (state) => {
    const lead = state.lead.lead;
    const leadLoggings = lead?.LeadLoggings;

    let preApprovedLoans = [];
    if (leadLoggings?.length) {
        preApprovedLoans = leadLoggings.filter((leadLog) => leadLog?.log_status === "Approved");
    }
    return preApprovedLoans;
};
export const selectBankDetails = (state) => state.lead.bankDetails;

export const {
    addLeadData,
    addStages,
    addPipelineData,
    setCreditScore,
    setCreditScoreVersion,
    setEnquiries,
    setConsent,
    setNotes,
    setCRIFData,
    setObligation,
    setLoanOffeDetails,
    setCRIFScoreMsg,
    setBankDetails,
} = LeadSlice.actions;
export default LeadSlice.reducer;
