export const SIGN_IN = "/auth/login";
export const SIGN_UP = "/auth/register";
export const SIGN_UP_OTP_EMAIL = "/auth/register/verifyOTPForEmail";
export const SIGN_UP_RESEND_OTP_EMAIL = "/auth/resend-email";
export const SIGN_UP_MOBILE = "/auth/register/send-otp-for-phone";
export const GST_CONSENT_REQUEST = "/probe/business-details/generateOTP";
export const SUBMIT_GST_OTP = "/probe/business-details/submitOTP";
export const SEND_EMAIL_CONSENT = "/probe/business-details/emailForGSTConsent";
export const SIGN_UP_MOBILE_OTP = "/auth/register/verify-otp-for-phone";
export const SIGN_UP_SUCCESS = "/auth/register/final-submit-create-static-data";
export const FORGET_PASSWORD_EMAIL = "/auth/forget";
export const UPDATE_TIMESTAMP_APPLY_LINK = "/bank/lead/preApproved/timestamp";
export const FORGET_PASSWORD_EMAIL_OTP = "/auth/verify-otp-for-forgot-password";
export const FORGET_PASSWORD_CHANGE = "/auth/change-password";
export const VERIFY_CUSTOMER_DOCUMENT = "/customer";
export const VERIFY_ALL_CUSTOMER_DOCUMENT = "/customer/document/verify";
export const SAVE_CUSTOMER_DOCUMENT_KYC_RESPONSE = "/customer/saveDocumentKycResponse";
export const GET_CUSTOMER_DOCUMENTS = "/customer/document";
export const RESEND_EMP_EMAIL = "/auth/empResendEmail";
export const GET_LEAD_STAGES = "/pipeline/get-stages";
export const DEACTIVE_LENDER = "/bank/staus";
export const GET_PIPELINE_AUTO_BUREAU_PULL = "/pipelineSettings/pullAutoBureauData/";
export const SET_PIPELINE_AUTO_BUREAU_PULL = "/pipelineSettings/pullAutoBureauData";
export const GET_PIPELINE_SETTINGS = "/pipelineSettings/getPipelineSettings";
export const UPDATE_PIPELINE_SETTINGS = "/pipelineSettings/updatePipelineSettings";
export const GET_PIPELINE_TO_GET_LEADS_FOR_PARTICIPANTS = "/pipelineSettings/getPipelineIdForGetLeads";
export const SET_PIPELINE_TO_GET_LEADS_FOR_PERTICIPANTS = "/pipelineSettings/setPipelineForGetLeadsForParticipants";
export const UPDATE_STAGE_STATUS_CI = "/bank/lead/stage-change/update";
export const SEND_SMS_STAGE_WISE = "/sms-template/send-stage-wise-individual-sms";
export const FETCH_CONTACT_API = "/global/get-api-lists";
export const BULK_CUSTOMER_UPLOAD = "/manage/lender/customer-upload-xlsx";
export const CREATE_LEAD_BY_BRE = "/manage/lender/move-customer-to-lead";
export const GET_ORGANIZATION_DETAILS = "/organization/getOrganizationDetails";
export const DELETE_ORGANIZATION = "/organization/removeOrganization";
export const ADD_ORGANIZATION_DETAILS = "/organization/addOrganization";
export const RESEND_VERIFICATION_EMAIL = "/auth/resendverify";
export const CREDIT_DETAILS = "/creditscore/crif";
export const CREDIT_DETAILS_DB = "/creditscore/crif/detail";
export const MOBILE_OTP = "/auth/register/verifyOTP";
export const GET_EDIT_BRE_RULE = "/business/get-rule-by-id";
export const DELETE_BRE_RULE = "/business/rule-update-delete";
export const GET_FILTERED_BRE_CUSTOMER_LIST = "/business/rules";
export const LOGOUT_ACTION = "/auth/logout";
export const LOGOUT = "/auth/logout";
export const TASK_LIST = "/task";
export const ADD_TASK = "/task";
export const USER_LIST = "/users";
export const STATUS_LIST = "/status";
export const DELETE_TASK = "/task";
export const UPDATE_TASK = "/task";
export const ADD_MEMBER = "/users";
export const UPDATE_TASK_STATUS = "/task/status";
export const TASK_DETAILS = "/task";
export const DELETE_MEMBER = "/users";
export const FORGOT_PASSWORD = "/resetPassword";
export const FORGOT_PASSWORD_MAIN = "/auth/pwd";
export const ASSIGN_TASK_LIST = "/userdata";
export const CREATE_PASSWORD = "/users";
export const GET_LEADS_LIST = "/pipeline/lead_search_with_filter/";
export const GET_LEADS_LIST_DOWNLOAD = "/pipeline/export_filtered_leads/";
export const ADD_ROLE = "/role";
export const PRIORITY_LIST = "/priority";
export const USER_DETAILS = "/users";
export const TASK_COMMENTS = "/comment";
export const ROLE_LIST = "/role";
export const UPDATE_ROLE = "/role";
export const ORGANISATION_DETAILS_ACTION = "/auth/organization";
export const TRELLO_BOARD_LIST = "/task/trello";
export const TOKEN_VERIFY = "/accesstoken/verify";
export const EMAIL_VERIFY = "/auth/verifytoken";
export const WORK_SPACE = "/auth/workspace";
export const GENERAL_DATA = "/auth/gk";
export const STAGE_LIST = "/stage";
export const DELETE_ROLE = "/role";
export const UPDATE_MEMBER = "/users";
export const GET_ROLES = "/users/roles";
export const GET_ACCESS_LEVEL = "/access_level";
export const GET_ACCESS_LEVEL_BY_ROLE = "/access_level/role";
export const UPDATE_ACCESS_LEVEL_BY_ROLE = "/access_level/role";
export const OTHER_USER_PROFILE_ACTION = "/users";
export const USER_PROFILE_ACTION = "/users/profile";
export const UPDATE_USER_PROFILE = "/users/profile";
export const UPDATE_LEAD_DATA = "/customer/lead/update";
export const DELETE_EMPLOYMENTLOCATION = "/users/company";
export const DELETE_EMPLOYMENTEDUCATION = "/users/education";
export const UPDATE_ORGANISATION = "/auth/organization";
export const TAGS_SEARCH = "/task/tags?tagsName";
export const DELETE_TASKMEMBER = "/task/assign";
export const DELETE_TASK_DOCUMENT = "/task/documents";
export const DELETE_USER_DOCUMENT = "/users/document";
export const ADD_TASK_MEMBER = "/task/assign";
export const PIPELINE_LIST = "/pipeline";
export const UPDATE_AADHAAR_DETAILS_MANUALLY = "/customer/updateAadhaarDetailsManually";
export const UPDATE_PAN_DETAILS_MANUALLY = "/customer/updatePANDetailsManually";
export const CREATE_DOCUMENT_TIMELINE = "/customer/createDocumentUpdateTimeline";
export const CUSTOMER_ADDRESS_UPDATE = "/customer/updateCustomerAddressManually";
export const FETCHED_DOCUMENT_TIMELINE = "/customer/fetchedCustomerDocTimeline";
export const VALIDATE_PAN = "/customer/lead/validateCRMPAN";
export const FETCH_ALL_PAN_DETAILS = "/customer/lead/fetchAllCRMPANDetails";
export const FETCH_ALL_PAN_BASIC_DETAILS = "/customer/lead/PANAllInOne";
export const TO_VERIFY_AADHAAR_FROM_OCR = "/customer/verifyAadhaarByOCR";
export const MODIFY_ENACH_DETAILS = "/customer/updateEnachDetails";
export const MODIFY_BANK_DETAILS = "/customer/updateBankDetails";
export const UPDATE_BANK_STATEMENT = "/customer/updateBankStatement";
export const UPDATE_PHOTOGRAPHER = "/customer/updateSelfie";
export const VERIFY_DOCS = "/customer/verifyDocs";

export const PARTNER_PIPELINE_LIST = "/partner/partner-details/";
// export const PARTNER_PIPELINE_LIST = "/pipeline";

export const LEADS_LIST = "/lead";
export const PIPELINE_DETAILS = "/pipeline";
export const CLIENT_PIPELINE_DETAILS = "/pipeline/getClientPipeline";
export const CLIENT_STAGE_DETAILS = "/pipeline/getStagesByPipeline";
export const CLIENT_STAGE_BY_ID = "/pipeline/getStagesByPipelineId";
export const MAP_STAGES = "/customer/mapStages";
export const GET_MAP_STAGES = "/customer/getMappedStages";
export const ADD_SINGLE_LEAD = "/pipeline/addLead";
export const ADD_PIPELINE = "/pipeline";
export const ADD_PIPELINE_PARTNER = "/pipeline/partner/assign";
export const LIST_PIPELINE_PARTNER = "/pipeline/partner/";
export const CHANGE_PASSWORD = "/users/changepassword";
export const RESETPASSWORD_ACTION = "/auth/forget";
export const DELETE_TAGS = "/task/tagsdelete";
export const UPDATE_TASK_DOCUMENT = "/task/documents";
export const LENDERSFIELDS = "/lender/fields";
export const DELETE_PIPELINE = "/pipeline";
export const UPDATE_PIPELINE = "/pipeline";
export const LAST_VIEW_DOCUMENT = "/task/viewed";
export const SHARE_PIPELINE = "/pipeline";
export const ADD_PIPELINE_CRITERIA = "/criteria";
export const CITY_LIST = "/pipeline/citylist";
export const ADD_API_KEY = "/secretkey";
export const PROFILE_VERIFICATION_EMAIL_SEND = "/users/resendUserSendEmail";
export const PROFILE_PHONE_SEND_OTP = "/users/resendUserSendPhoneSMS";
export const PROFILE_EMAIL_VERIFICATION = "/users/userVerifyEmail";
export const PROFILE_PHONE_VERIFICATION = "/users/userVerifyPhone";
export const SEND_USER_VERIFICATION_EMAIL = "/users/resendSingleUserSendEmail/";
export const VERIFY_USER_VER_EMAIL = "/users/singleUserVerifyEmail/";
export const SEND_USER_VER_PHONE = "/users/resendSingleUserSendPhoneSMS/";
export const VERIFY_USER_VER_PHONE = "/users/singleUserVerifyPhone/";
export const SECRET_KEYS = "/secretkey/pipeline";
export const ADD_LEADSOURCE = "/leadsource";
export const UPDATE_SECRETKEY = "/secretkey";
export const DELETE_SECRETKEY = "/secretkey";
export const UTMSOURCE_LIST_BY_SECRETKEY = "/secretkey";
export const DELETE_PIPELINE_PARTNER = "/pipeline/partner";
export const ADD_PIPELINE_LOGO = "/secretkey/logo";
export const PIPELINE_LOGO = "/secretkey/logo";
export const DELETE_PIPELINE_LOGO = "/secretkey/logo";
export const UPDATE_PIPELINE_LOGO = "/secretkey/logo";
export const PIPELINE_LEADS = "/pipeline/leads";
export const DASHBOARD_DATA = "/dashboard";
export const UPDATE_LEAD_PUSH_STATUS = "/bank/leadPushStatus";
export const DASHBOARD_DATA_BY_DATE = "/dashboard/date";
export const LEADS_DETAILS_API = "/pipeline/leads/";
export const UPDATE_LEADS_DATA = "/pipeline/leads";
export const MULTIFILTER_LEAD_COUNT = "/pipeline/lead_search_with_filter_data_count";
export const SEARCH_FILTER_LEAD_COUNT = "/pipeline/lead_search_data_count";
export const SEARCH_BY_DATE_FILTER_LEAD_COUNT = "/pipeline/lead_search_with_date_data_count";
export const LEAD_FILTER_COUNT = "/pipeline/leads_data_count";
export const CUSTOMER_LIST = "/customer";
export const PARTNER_LIST = "/partner/partner-list";
export const PARTNER_ACTIVATE_DEACTIVATE = "/partner/partner-activation";
export const PARTNER_SEARCH = "/partner/partner-search";
// export const ADD_PARTNER = "/partner/addPartner";
export const ADD_PARTNER = "/partner/add-partner";
export const GET_COMPANY_LOGO = "/partner/partner-getOrganizationDetails";

export const GET_OTP_CUSTOMER = "/customers/send-customers-otp";
export const DELETE_BORROWER = "/customers/delete-borrower";
export const VERIFY_OTP_FOR_CSUSTOMER_DELETE = "/customers/verifiy-for-borrower-delete";

// export const PARTNER_DETAILS_INFO_PAGE = "/partner/getpartner-details/";
export const PARTNER_DETAILS_INFO_PAGE = "/partner/partner-details/";

///// Branch //////
export const EMPLOYEE_LIST = "/access_level/get-roles";
export const PRODUCT_LIST = "/branch/get-all-products";
export const BRANCH_CREATE_PRODUCT_LIST = "/branch/get-all-pipeline-assign-products";

export const Add_Branch = "/branch/create-branch";
export const BRANCHES_LIST = "/branch/get-branchs";
export const BRANCH_DETAILS = "/branch/get-branch-details-by-id";
export const BRANCH_DELETE = "/branch/delete-branch";
export const BRANCH_SETTING = "/branch/create-get-branch-settings";
export const BRANCH_SEARCH = "/branch/searching";
export const EMPLOYEE_DETAILS_BY_ROLE_ID = "/branch/get-employee-details-by-role_id";
export const EDIT_BRANCH = "/branch/update-branch";
export const GET_ALL_BRANCH_RELATED_PRODUCT = "/branch/get-all-branch-related-products";
export const GET_UNASSIGNED_PRODUCTS = "/branch/get-unassign-branch-related-products";
export const CREATE_PRODUCT_UNDER_BRANCH = "/branch/create-product-under-branch";
export const REMOVE_EMPLOYEE_FROM_BRANCH = "/branch/remove-employee";
export const ADD_ROLES_TO_BRANCH = "/branch/assign-employee";
export const ACTIVATE_DEACTIVATE_PRODUCT = "/branch/disable-product";

///////branch/get-branchs

export const ASSIGN_PIPELINE_TO_PARTNER = "/partner/partner-assign-pipeline";
export const PIPELINE_LIST_FOR_ASSIGN = "/partner/partner-pipeline-list";
export const PARTNER_PIPELINE_ADD_API_KEY = "/partner/partner-secretkey";
export const PARTNER_SECRET_KEYS = "/partner/partner-source-list";
export const PARTNER_DELETE_SECRETKEY = "/partner/partner-source-delete";
export const PARTNER_GET_LEAD_STAGES = "/partner/get-stages";
export const PARTNER_GET_LEADS_LIST = "/partner/lead_search_with_filter/";

export const UPLOAD_CUSTOMER_DOCUMENT = "/customer";
export const ADD_PIPELINE_USER = "/pipeline/user";
export const PIPELINE_SINGLE_USER = "/pipeline/user/";
export const PIPELINE_ALL_USER = "/pipeline/users/";
export const SMS_STAGE_WISE = "/pipeline/get-sms-stage-wise/";

export const DELETE_PIPELINE_USER = "/pipeline/user/";
export const UPLOAD_EXCEL_DOCUMENT = "/pipeline/leads/addexcel";
export const ADD_STAGE_TEMPLATE_URL = "/template/stage";
export const GET_SINGLE_STAGE_TEMPLATE_URL = "/template/stage/";
export const GET_ALL_STAGE_TEMPLATE_URL = "/template/stage";
export const EDIT_STAGE_TEMPLATE_URL = "/template/stage/";
export const DELETE_STAGE_TEMPLATE_URL = "/template/stage/";
export const ADD_BUSINESS_RULE_URL = "/business/add-rules";
export const GET_BUSINESS_RULE_URL = "/business";
export const GET_USER_APPLICATION_DASHBOARD = "/dashboard/application";
export const GET_LENDER_DASHBOARD = "/dashboard/lenders";

export const GET_CLIENT_DASHBOARD = "/secretkey/getSourceNamesByPipelineIDs";
export const GET_SourceDetails_ByName = "/secretkey/getSourceDetailsBySourceName";

export const GET_DOMAIN_FAVICON = "/users/getLenderDetails";
export const GET_SOURCELINKS_BY_PIPELINES = "/secretkey/getSecretKeysByPipelineIDs";
export const GET_PARTNER_DASHBOARD = "/dashboard/partner";
export const PAN_VERIFICATION = "/basic-dashboard/myOrganization/validate-pan";
export const SEND_AADHAAR_OTP = "/basic-dashboard/myOrganization/sendAadhaarOTP";
export const CLIENT_UPDATE = "/basic-dashboard/myOrganization/updateClientType";
export const PAN_UPDATE_IN_EMPLOYEE = "/basic-dashboard/myOrganization/updatePANCard";
export const VERIFY_AADHAAR = "/basic-dashboard/myOrganization/verifyAadhaar";
export const GET_CONTACT_ID_BY_PAN = "/basic-dashboard/myOrganization/getContactIdByPAN";
export const GET_BUSINESS_RULE_LIST = "/business/add-rules";
export const GET_BUSINESS_RULE_COUNT_BY_ID = "/business/get-count-by-rule-id";
export const GET_BUSINESS_RULE_LIST_PAGI = "/business/add-rule-details";
export const DASHBOARD_LENDER_DETAILS = "/dashboard/lenders/";

export const DASHBOARD_Client_DETAILS = "/secretkey/getSourceNamesByPipelineIDs";

export const MARKET_PLACE_DOMAIN_URL = "/company/list";
export const SAVE_MARKET_PLACE_DOMAIN_URL = "/pipeline/";
export const MARKET_PLACE_DOMAIN_LIST_URL = "/company/transfer/lead/";
export const SINGLE_LEAD_ELIGIBILITY_URL = "/bank/singleLead/eligibility";
export const AUTO_LOGIN_LIST_URL = "/bank/autologin/details/";
export const ALL_BANK_CREDENTIAL_LIST_URL = "/bank/credential";
export const ADD_BANK_AUTO_LOGIN_URL = "/bank/autologin/add";
export const ENABLE_DISABLE_AUTO_LOGIN = "/bank/autologin/";
export const TIMELINE_DATA_URL = "/bank/timeline/status";
export const GLOBAL_NOTES_DATA_LIST = "/pipeline/lead/notes/";
export const ADD_GLOBAL_NOTES_DATA_LIST = "/pipeline/lead/notes";
export const AUTO_LOGIN_CRON_REFRESH = "/bank/autologin";
export const CRON_GET_STATUS_URL = "/bank/cronStatus/get";
export const CRON_ADD_STATUS_URL = "/bank/cronStatus/add";
export const CRON_UPDATE_STATUS_URL = "/bank/cronStatus/update";
export const ACTIVE_BANK_LIST_LEADS_URL = "/pipeline/leads/logs";
export const ACTIVE_BANK_LIST_URL = "/bank/credential";
export const SINGLE_LEAD_BANK_STATUS = "/bank/singleLead/status";
export const TIMELINE_BANK_STATUS_URL = "/bank/timeline/status";
export const BANK_LIST_URL = "/bank/list";
export const UPDATE_AMOUNT = "/bank/lead/update/amount";
export const DELETE_AUTOLOGIN = "/bank/autologin/";
export const BANK_LIST = "/bank/list";
export const BANK_LIST_PIPELINEWISE = "/bank/participantList/";
export const CHECK_STAGES_SEQUENCE = "/template/compareStages";
export const BANK_CREDENTIAL_URL = "/bank/credential";
export const GET_DOMAINS = "/bank/getDomainListFromParticipant";
export const SUBMIT_EMAILS = "/bank/setEmailsOfParticipants";
export const GET_EMAILS = "/bank/getParticipantsEmails";
export const SEND_LEAD_THROUGH_EMAIL = "/bank/sendLeadsThroughEmail";
export const SET_UTM_LINK = "/bank/addUTMLink";
export const GET_UTM_LINK = "/bank/getUTMLink";
export const CUSTOMER_DOCUMENT_DELETE_URL = "/customer/document/delete";
export const CUSTOMER_DOCUMENT_VERIFY_URL = "/customer/document/verify/";
export const CUSTOMER_DOCUMENT_UPDATE_URL = "/customer/document/update/";
export const SINGLE_LEAD_BANK_PUSH = "/bank/singleLead/push";
export const GET_ASSIGN_BRE = "/bre-checks/get-assigned-bre-list-crm";
export const CHECK_BRE_CRITERIA = "/bre-checks/check-lead-is-eligible-or-not-for-crm";
export const ASSIGN_PIPELINE_LIST = "/pipeline/assign/leads";
export const BULK_LEAD_PUSH_URL = "/bank/bulkLead/push";
export const PIPELINE_ACCESS_LEAD = "/pipeline/access/lead";
export const PIPELINE_ARCHIVE_DELETE = "/pipeline";
export const ALL_IN_ONE_PAN = "/customers/CRM/fetchAllPANDetails";
export const FETCH_CUSTOMERS_WITH_PAN = "/customers/CRM/fetchAllCustomersWithPAN";
export const GET_ACCEPTED_AMOUNT = "/bank/lead/approved/amount";
export const UPDATE_LEAD_FAVORITE_LEAD_STATUS_URL = "/pipeline/leads/is_fivorate";
export const UPDATE_LEAD_SEEN_STATUS_URL = "/pipeline/leads/is_seen";
export const NEXT_STAGE_URL = "/bank/lead/status/update";
export const LEAD_STAGE_LIST = "/pipeline/stage_details/";
export const SEARCH_PIPLELINE_LEADS = "/pipeline/lead_search/";
export const SEARCH_LEADS_BY_DATE = "/pipeline/lead_search_with_date";
export const MULTI_FILTER_LEAD_LIST = "/pipeline/lead_search_with_filter";
export const UDPATE_OFFER_SETTINGS = "/pipeline/add-offer-setting";
export const UDPATE_OFFER_SETTINGS_DYNAMIC = "/pipeline-setting/create-pipeline-setting";
export const DELETE_OFFER_SETTINGS_DYNAMIC = "/pipeline-setting/delete-pipeline-settings_by_id";
export const CRITERIA_OFFER_SETTINGS_DYNAMIC = "/criteriya/create-criteriya";
export const UPDATE_PIPELINE_OFFER_SETTINGS_DYNAMIC = "/pipeline-setting/update-pipeline-setting";
export const HISTORY_OFFER_SETTINGS_DYNAMIC = "/pipeline-setting/audit-trail";
export const UNASSIGNED_CRITERIA_OFFER_SETTINGS_DYNAMIC = "/pipeline-setting/criteria-un-assigned";
export const ACTIVE_DEACTIVE_OFFER_SETTINGS_DYNAMIC = "/pipeline-setting/pipeline-setting-activate-or-inactive";
export const SEQUENCE_REORDERING_OFFER_SETTINGS_DYNAMIC = "/pipeline-setting/sequence-re-ordering";
export const GLOBAL_AUDIT_TRAIL_OFFER_SETTINGS_DYNAMIC = "/pipeline-setting/global-audit-trail";

export const ADD_OFFER_SETTINGS = "/pipeline/get-offer-setting";
export const GET_CUSTOMER_PIPELINE_SETTINGS = "/pipeline-setting/get-all-pipeline-settings";
export const GET_CUSTOMER_OFFER_SETTINGS_CRITERIA_WISE = "/pipeline-setting/criteria-wise-eligible_settings";

export const ADD_OFFER_SETTINGS_DYNAMIC = "/pipeline-setting/get-all-pipeline-settings";
export const BASE64_IMAGE = "/users/convert/imageToBase64";
export const GET_CUSTOMER_CONSENT_LIST = "/customer/consent";
export const GET_CUSTOMER_ENQUIRY_LIST = "/customer/enquiry";

export const ADD_SMS_TEMPLATE = "/sms-template/create-sms-template";

export const GET_VARIABLE_VALUE = "/sms-template/get-variable-value";
export const GET_SMS_TEMPLATE = "/sms-template/get-sms-template";
export const EDIT_SMS_TEMPLATE = "/sms-template/edit-sms-template";

export const BULK_SMS_TEMPLATE = "/sms-template/send-demo-sms";
export const INDIV_SMS_TEMPLATE = "/sms-template/send-individual-sms";

export const GET_ROLE_ACCESS_PERMISSION = "/access_level/role";
// * Loan Offer Agreement
export const GET_AGREEMENT = "/agreement/getLoanAgreement";
export const ADD_AGREEMENT = "/agreement/addLoanAgreement";
export const UPDATE_AGREEMENT = "/agreement/updateLoanAgreement";
export const DELETE_AGREEMENT = "/agreement/deleteLoanAgreement";
export const GET_LOAN_OFFER_DETAILS = "/agreement/getLoanOfferDetails";
export const ADD_LOAN_OFFER_DETAILS = "/agreement/crm/addLoanOfferDetails";
export const UPDATE_LOAN_OFFER_DETAILS = "/customer/updateLoanOfferDetails";
export const GET_STAGE_WISE_SMS = "/pipeline/get-sms-stage-wise";
export const SEND_AGREEMENT_MAIL = "/agreement/sendAgreement";
export const GET_AGREEMENT_HISTORY = "/agreement/getAgreementHistory";

// Repayment Schedule
export const FETCH_REPAYMENT_SCHEDULE = "/pipeline/genarate-emi-schedule/repayment";
export const FETCH_REPAYMENT_SCHEDULE_V2 = "/pipeline-setting/genarate-repayment-schedule-pipeline-settings-wise";

// * Agreement Document
export const GET_AGREEMENT_DOCUMENT = "/agreement/getAgreementDocument";
export const ADD_AGREEMENT_DOCUMENT = "/agreement/addAgreementDocument";
export const UPDATE_AGREEMENT_DOCUMENT = "/agreement/updateAgreementDocument";
export const DELETE_AGREEMENT_DOCUMENT = "/agreement/deleteAgreementDocument";

// * Sanction Letter
export const GET_SANCTION_LETTER = "/sanctionLetter/getSanctionLetterTemplate";
export const ADD_SANCTION_LETTER = "/sanctionLetter/addSanctionLetterTemplate";
export const DELETE_SANCTION_LETTER = "/sanctionLetter/deleteSanctionLetterTemplate";
// * Sanction Letter Document
export const GET_SANCTION_LETTER_DOCUMENT = "/sanctionLetter/getSanctionLetterDocument";
export const ADD_SANCTION_LETTER_DOCUMENT = "/sanctionLetter/addSanctionLetterDocument";
export const DELETE_SANCTION_LETTER_DOCUMENT = "/sanctionLetter/deleteSanctionLetterDocument";
export const UPDATE_SANCTION_LETTER_DOCUMENT = "/sanctionLetter/updateSanctionLetterDocument";
export const SEND_SANCTION_LETTER_MAIL = "/sanctionLetter/sendSanctionLetter ";
export const GET_SANCTION_LETTER_HISTORY = "/sanctionLetter/getSanctionLetterDocumentHistory";
export const FETCH_DOCUMENT_CONTENT = "/pipeline/fetchWordDocumentContent";

export const ALL_MARKETPLACE_APP_LIST = "/marketPlace/appList";

export const ADD_MARKETPLACE_APP_CREDENTIAL = "/marketPlace/addMarketplaceCredential";

export const GET_MARKETPLACE_CREDENTIAL = "/marketPlace/getMarketplaceCredential";
export const SEND_TEST_MAIL = "/marketPlace/sendTestEmail";
export const DISCONNECT_MARKETPLACE_APP = "/marketPlace/disconnectApp";
export const GET_CUSTOMERS_LIST = "/customer/contactlist_with_search_filter";
export const GET_CUSTOMER_DETAILS = "/pipeline/customer-details";
export const PERMISSION_FOR_REPORT_BI = "/superset/get-Superset-Dashboard-By-domainName";

export const UPDATE_SRCE_NAME = "/customer/update_sourcename";

export const GET_CUSTOMERS_LIST_WITHOUT_PAN = "/probe/business-details-without-contact-id";
export const GET_PROBE_BUSINESS_LIST = "/probe/businesslist_with_search_filter";
export const ADD_PROBE_BUSINESS = "/probe/company_base_details";
export const CREATE_TIMELINE = "/customer/createTimeline";
export const GET_PROBE_BUSINESS_DETAILS = "/probe/business-details";

export const VERIFY_CUSTOMER_EMAIL = "/customer/verifyCustomerEmail";
// export const GET_CUSTOMER_DETAILS = "/pipeline/customer-details"

export const GET_CAM_DETAILS = "/customer/customerReport";
export const CHATBOT_API = "api-get-reliance-post";

export const GET_BRE_ELIGIBLE_HISTORY = "/customer/lead/get-bre-lead-eligible-history";
// export const UPDATE_SMS_STAGE_WISE = "/pipeline/update-sms-stage-wise";
export const UPDATE_SMS_STAGE_WISE = "/pipeline/update-sms-stage-wise";
export const GET_BANK_DETAILS = "/customer/getBankDetails";

export const MANUAL_LEAD_PUSH_TO_LMS_ALL_API = "/crm-lms/customer/all-lms-apis";

// * LMS ---------------------

export const GET_AUTH_TOKEN = "/crm-lms/customer/get-auth-token";
export const CREATE_CLIENT = "/crm-lms/customer/create-customer";

export const CREATE_CLIENT_IDENTIFIER = "/crm-lms/customer/create-client-identifiers"; //depricated

export const CREATE_IDENTIFIER_USING_PAN = "/crm-lms/customer/create-identifiers-using-pan";
export const CREATE_IDENTIFIER_USING_AADHAAR = "/crm-lms/customer/create-identifiers-using-aadhaar";
export const UPLOAD_DOCUMENT_IDENTIFIER_FOR_PAN_FRONT = "/crm-lms/customer/upload-document-identifier-for-pan-front";
export const UPLOAD_DOCUMENT_IDENTIFIER_FOR_AADHAAR_FRONT =
    "/crm-lms/customer/upload-document-identifier-for-aadhaar-front";
export const UPLOAD_DOCUMENT_IDENTIFIER_FOR_AADHAAR_BACK =
    "/crm-lms/customer/upload-document-identifier-for-aadhaar-back";

export const CREATE_ADDRESS_DETAILS = "/crm-lms/customer/create-address-details";
export const CREATE_BANK_ACCOUNT = "/crm-lms/customer/create-customer-bank-account";
export const UPLOAD_IDENTIFIER_DOCUMENTS = "/crm-lms/customer/upload-document-identifier";
export const ACTIVE_BANK_ACCOUNT = "/crm-lms/customer/active-bank-account";
export const GET_CUSTOMER_BANK_ACCOUNT = "/crm-lms/customer/get-customer-bank-details";
export const UPLOAD_CLEINT_DOCUMENT = "/crm-lms/customer/upload-document";
export const CREATE_LOAN = "/crm-lms/customer/create-loan";
export const GET_DETAILS_FOR_SINGLE_API = "/crm-lms/customer/get-details-for-single-api";
export const APPROVE_LOAN_AMOUNT = "/crm-lms/customer/approve-loan";
export const DISBURSE_LOAN_AMOUNT = "/crm-lms/customer/disburse-loan";
export const GET_CLIENT_IDENTIFIERS = "/crm-lms/customer/get-customer-by-phone";
export const GET_LMS_REQ_RES_HISTORY = "/crm-lms/customer/get-lms-req-res-history";

// * LMS ---------------------

// * LMS History
export const GET_LMS_HISTORY = "/crm-lms/customer/get-lms-status-details";

// * AuthBridge
export const ANALYZE_BANK_STATEMENT = "/customer/analyzeBankStatement";
export const ANALYZE_SALARY_SLIP = "/customer/analyzeSalarySlip";
// * AuthBridge

export const SHOW_SIMULATION_OUTPUT = "/pipeline/genarate-emi-schedule/repayment";

export const GET_ALL_API_LIST = "/productAdmin/manage/api-list/showApiList";
export const SHOW_BENCHMARK_INT_TIMELINE = "/benchmark/get-banckmarks";
export const ADD_BENCHMARK_INT = "/benchmark/create-benchmark";
export const GET_BENCHMARK_DETAILS = "/benchmark/get-benchmark-details-by-id";
export const EDIT_BENCHMARK_INT = "/benchmark/update-benchmark";

// * APIs Client wise

// * MyFinDoc
export const GET_RATE_OF_INTEREST = "/customer/get-rate-of-interest";
